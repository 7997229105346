.condensed-taxi-form .progress {
  display: flex;
  flex-direction: column-reverse;
}

.condensed-taxi-form .progress__max {
  background-color: #fbfaf9 !important;
  border: 1px solid #1f453d;
  height: 16px !important;
}

.condensed-taxi-form .progress__bar {
  background-color: #1f453d !important;
}

.condensed-taxi-form .progress__label {
  width: 100%;
  text-align: center;
  margin-top: 0 !important;
}

.condensed-taxi-form .label {
  font-size: 1.125rem;
  font-weight: normal !important;
  margin-bottom: 16px !important;
}
