.taxi-form-section {
  scroll-margin-top: 90px;
}
.taxi-form .quiz-body .card__body {
  padding-left: 0;
  padding-right: 0;
}
.taxi-form .quiz-card {
  padding: 2rem 0;
}
.taxi-form .quiz-body .u--text-danger {
  padding-left: 0.25rem;
}
.taxi-form .quiz-body .error-block {
  margin: 0 auto;
  max-width: 27rem;
  padding: 0;
}
.taxi-form .quiz-body,
.taxi-form .quiz-footer-mount-point {
  margin: 0 auto;
  max-width: 45rem;
  width: 80%;
}
.taxi-form .quiz-card .progress__bar {
  background: #080;
}
.taxi-form .quiz-lead-form {
  margin: 0 auto;
  max-width: 25rem;
}
.taxi-form .quiz-body input,
.taxi-form .quiz-body select {
  border-radius: 0.5rem;
}
.taxi-form .quiz-body select.control {
  background-size: 1.5rem;
}
.taxi-form select.control {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwb2x5Z29uIHBvaW50cz0iMTI4LDE5MiAyNTYsMzIwIDM4NCwxOTIgCSIvPg0KPC9nPg0KPC9zdmc+DQo=");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  display: block;
  padding-right: 2.0625rem;
}
.taxi-form select.control.focus,
.taxi-form select.control:focus {
  box-shadow: 0 0 0 2px #408eaa;
  -webkit-box-shadow: 0 0 0 2px #408eaa;
  outline: #0000;
}
.taxi-form .quiz-footer-container .progress__max {
  background-color: #c5c5c5;
  margin: auto;
  width: 80%;
}
.taxi-form .quiz-footer-container .straight-line {
  background-color: #c5c5c5;
  height: 0.15rem;
  position: relative;
  top: -1.3rem;
  width: 100%;
}
.taxi-form .quiz-footer-container .progress__bar,
.taxi-form .quiz-footer-container .progress__max {
  border-radius: 1rem;
  height: 0.5rem;
  position: relative;
  z-index: 100;
}
.taxi-form .quiz-footer-container .progress__bar {
  background: #4ab010;
}
@media only screen and (min-width: 768px) {
  .taxi-form .quiz-main-next {
    width: 8rem;
  }
}
.taxi-form .quiz-navigation {
  margin: 0 auto;
  width: 80%;
}
.taxi-form .quiz-body .control,
.taxi-form .quiz-body .input,
.taxi-form .quiz-body .label {
  color: #565656;
}
.taxi-form .quiz-body .checkbox .control,
.taxi-form .quiz-body .radio .control {
  top: 31%;
  transform: scale(1.5);
}
.taxi-form .quiz-body .checkbox .control {
  padding-left: 2rem;
}
.taxi-form .quiz-body .checkbox .label,
.taxi-form .quiz-body .radio .label {
  padding-left: 2.5rem;
}
.taxi-form .quiz-body .checkbox,
.taxi-form .quiz-body .radio {
  border: none;
}
.taxi-form .checkbox .control,
.taxi-form .radio .control {
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  display: inline;
  left: 0.6875rem;
  margin-left: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 15px;
  z-index: 2;
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  background-color: currentColor !important;
}
.taxi-form .control {
  background-color: #f9f9f9;
  border: 1px solid #999;
  border-radius: 4px;
  box-shadow: inset 0;
  box-sizing: border-box;
  color: #282828;
  display: block;
  line-height: 1.5;
  padding: 0.40625rem 0.6875rem;
  width: 100%;
}
.taxi-form .big-step-display:focus {
  border-radius: 2px;
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 4px #036ad2;
  outline: none;
}
.taxi-form .small-step-display,
.taxi-form .small-step-display p {
  margin: 0;
}
.taxi-form .quiz-body .info-text {
  padding: 0 0 1rem !important;
}
.taxi-form .quiz-footer-mount-point .info-text {
  text-align: center;
}
.taxi-form .label {
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.33rem;
  position: relative;
  text-align: left;
  white-space: inherit;
}
.taxi-form h2 {
  background: #fff;
  border: 0;
  color: #000;
  font-family: sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
  margin: 1rem 0;
  padding: 0;
}
.taxi-form .card {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 2px #0003;
  color: inherit;
  margin: 0 0 1rem;
}
.taxi-form .card__body,
.taxi-form .modal__body {
  margin-bottom: 1rem;
  padding: 1.4375rem;
}
.taxi-form .u--bc-danger,
.taxi-form .u--border-color-danger,
.taxi-form .u--border-danger {
  border-color: #d12c1a !important;
}
.taxi-form .u--bl,
.taxi-form .u--border-left {
  border-left: 1px solid #ccc;
}
.taxi-form .u--border-top,
.taxi-form .u--bt {
  border-top: 1px solid #ccc;
}
.taxi-form .card__body > :last-child,
.taxi-form .modal__body > :last-child {
  margin-bottom: 0;
}
.taxi-form .card__footer,
.taxi-form .modal__footer {
  border-top: 1px solid #ccc;
  font-size: 1.125rem;
  padding: 1.4375rem;
  text-align: left;
}
.taxi-form .tab-list + .card__footer,
.taxi-form .tab-list + .modal__footer {
  border-top: 0;
}
.taxi-form .form-group {
  border: 0;
  margin: 0 0 1.5rem;
  padding: 0;
  position: relative;
  transition:
    border 0.15s ease,
    padding 0.15s ease;
}
.taxi-form .form-group.is--disabled .label {
  cursor: not-allowed;
  opacity: 0.5;
}
.taxi-form .form-group.is--disabled .label:active,
.taxi-form .form-group.is--disabled .label:focus {
  background-color: #0000;
  outline: none;
}
.taxi-form .form-group.is--disabled .control {
  cursor: not-allowed;
  opacity: 0.5;
}
.taxi-form .form-group.is--invalid {
  border-left: 4px solid #d12c1a;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
}
.taxi-form .form-group.is--invalid .label__error-message {
  display: block;
}
.taxi-form .form-group.is--invalid .control {
  border-color: #d12c1a;
}
.taxi-form .form-group.is--invalid textarea.control {
  padding: 0.40625rem 0.6875rem;
}
.taxi-form .form-group.is--invalid .form-group__validation-icon {
  color: #d12c1a;
  display: inline-block;
}
.taxi-form .form-group.is--invalid .form-group__validation-icon:after {
  content: "";
}
.taxi-form .form-group.is--invalid select + .form-group__validation-icon {
  display: none;
}
.taxi-form .form-group.is--valid {
  border-left: 0;
  padding-left: 0;
}
.taxi-form .form-group.is--valid .control {
  padding-right: 2.25rem;
}
.taxi-form .form-group.is--valid .form-group__validation-icon {
  color: #080;
  display: inline-block;
}
.taxi-form .form-group.is--valid .form-group__validation-icon:after {
  content: "";
}
.taxi-form .form-group.is--valid select + .form-group__validation-icon {
  display: none;
}
.taxi-form .form-group__validation-icon {
  -webkit-font-smoothing: antialiased;
  background-color: #0000;
  bottom: 0;
  display: none;
  font-family: Harmony Icons;
  font-size: 1rem;
  font-style: normal;
  height: 2.25rem;
  line-height: 2.25rem;
  position: absolute;
  right: 0;
  text-align: center;
  width: 2.25rem;
}
.taxi-form .form-group__help-text {
  display: block;
  font-weight: 400;
  margin: 0.33rem 0 0;
}
.taxi-form fieldset.form-group legend {
  border-bottom: inherit;
  display: table;
  float: left;
  font-size: inherit;
  padding: 0;
  width: 100%;
}
.taxi-form fieldset.form-group legend + * {
  clear: both;
}
.taxi-form .form-group--small {
  max-width: 10rem;
}
.taxi-form .form-group--medium {
  max-width: 20rem;
}
.taxi-form .form-group--large {
  max-width: 30rem;
}
.taxi-form .progress {
  margin: 0 0 1rem;
  overflow: visible;
}
.taxi-form div.card div.progress {
  background: #fff;
  box-shadow: 0 !important;
}
.taxi-form .progress.is--loading .progress__max {
  overflow: hidden;
}
.taxi-form .progress.is--loading .progress__bar {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: progress-loading;
  width: 80% !important;
}
@keyframes progress-loading {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(125%);
  }
}
.taxi-form .progress__label {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.taxi-form .progress__value {
  float: right;
  font-weight: 700;
  height: 1rem;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}
.taxi-form .progress__value:first-child {
  display: block;
  float: none;
  margin: 0.5rem 0;
  text-align: right;
}
.taxi-form .progress__max {
  background-color: #0003;
  height: 8px;
}
.taxi-form .progress__bar {
  background-color: #1673cd;
  height: 100%;
  max-width: 100%;
  transition: width 1s;
  width: 0;
}
.taxi-form .progress--theme-dark .progress__bar {
  background-color: #3978a2;
}
.taxi-form .progress--theme-light .progress__bar {
  background-color: #ededed;
}
.taxi-form .progress--theme-cta .progress__bar {
  background-color: #3978a2;
}
.taxi-form .progress--theme-accent .progress__bar {
  background-color: #fbe100;
}
.taxi-form .progress--theme--green .progress__bar {
  background-color: #1f453d;
}
.taxi-form .button {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #282828;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.40625rem 0.9375rem;
  text-align: center;
}
.taxi-form .button.focus,
.taxi-form .button:focus {
  box-shadow: none;
  outline: 0;
  position: relative;
}
.taxi-form .button.focus:before,
.taxi-form .button:focus:before {
  border: 2px solid #408eaa;
  border-radius: calc(0.375rem + 3px);
  bottom: -4px;
  content: "";
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}
.taxi-form .button.focus.active:before,
.taxi-form .button.focus:active:before,
.taxi-form .button:focus.active:before,
.taxi-form .button:focus:active:before {
  opacity: 0.75;
}
.taxi-form .button.focus.disabled:before,
.taxi-form .button.focus:disabled:before,
.taxi-form .button:focus.disabled:before,
.taxi-form .button:focus:disabled:before {
  display: none;
}
.taxi-form .button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.taxi-form .button--block {
  display: block;
  padding: 0.875em 1em;
  width: 100%;
}
.taxi-form .button--theme-cta {
  @apply rounded-md text-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-brand text-brand-foreground shadow border-2 border-brand hover:bg-white hover:text-brand h-auto px-4 py-2;
}
/* .taxi-form .button--theme-cta:focus,
.taxi-form .button--theme-cta:hover {
  background: linear-gradient(180deg, #3978a2, #3978a2);
  color: #fff;
}
.taxi-form .button--theme-cta:active {
  color: #fff;
} */
.taxi-form .button--theme-cta .badge {
  background-color: #fff;
  color: #282828;
}
.taxi-form .u--text-danger,
.taxi-form .u--text-danger:hover {
  color: #d12c1a;
}
.taxi-form .checkbox-group:after,
.taxi-form .radio-group:after {
  clear: both;
  content: "";
  display: block;
}
.taxi-form .checkbox-group--inline .checkbox,
.taxi-form .radio-group--inline .radio {
  float: left;
}
.taxi-form .checkbox-group--inline .checkbox:first-of-type,
.taxi-form .checkbox-group--inline .checkbox:first-of-type .label,
.taxi-form .radio-group--inline .radio:first-of-type,
.taxi-form .radio-group--inline .radio:first-of-type .label {
  border-radius: 4px 0 0 4px;
}
.taxi-form .checkbox-group--inline .checkbox:last-of-type,
.taxi-form .checkbox-group--inline .checkbox:last-of-type .label,
.taxi-form .radio-group--inline .radio:last-of-type,
.taxi-form .radio-group--inline .radio:last-of-type .label {
  border-radius: 0 4px 4px 0;
}
.taxi-form .checkbox-group--inline .checkbox:not(:last-child),
.taxi-form .radio-group--inline .radio:not(:last-child) {
  border-right: 0;
}
.taxi-form .u--margin-bottom-2,
.taxi-form .u--mb2 {
  margin-bottom: 1rem !important;
}
.taxi-form .u--al,
.taxi-form .u--align-left {
  text-align: left;
}
.taxi-form .u--padding-top-2,
.taxi-form .u--pt2 {
  padding-top: 1rem !important;
}
.taxi-form .u--padding-2 {
  padding: 1rem !important;
}
.taxi-form .h4 {
  font-weight: 600;
}
.taxi-form [type="checkbox"],
.taxi-form [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.taxi-form button,
.taxi-form input,
.taxi-form select,
.taxi-form textarea {
  font: inherit;
  margin: 0;
}
.taxi-form button,
.taxi-form input {
  overflow: visible;
}
.taxi-form .checkbox,
.taxi-form .radio {
  background-color: #fff;
  cursor: pointer;
  display: block;
  margin-top: inherit;
  overflow: hidden;
  padding-left: inherit;
  position: relative;
}
.taxi-form .checkbox:last-of-type:first-of-type,
.taxi-form .checkbox:last-of-type:first-of-type .label,
.taxi-form .radio:last-of-type:first-of-type,
.taxi-form .radio:last-of-type:first-of-type .label {
  border-radius: 4px;
}
.taxi-form .checkbox .control:checked + .label,
.taxi-form .radio .control:checked + .label {
  background-color: #e6f1fc;
}
.taxi-form .checkbox .label,
.taxi-form .radio .label {
  border: 0;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.40625rem 1em 0.40625rem 2.0625rem;
  position: relative;
  z-index: 1;
}
.taxi-form a {
  background-color: #0000;
  color: #2278b5;
  text-decoration: none;
}
.taxi-form .u--m0,
.taxi-form .u--margin-0 {
  margin: 0 !important;
}
.taxi-form .u--jsb,
.taxi-form .u--justify-space-between {
  justify-content: space-between !important;
}
/* THIS IS A TEMP FIX TO MAKE THE CHECKBOXES ALIGN */
.taxi-form .u--jsb,
.taxi-form .u--justify-space-between {
  justify-content: start !important;
}
.taxi-form .u--jsb,
.taxi-form .u--justify-space-between .form-group {
  flex-basis: auto !important;
  width: auto !important;
}
.taxi-form .u--jsb,
.taxi-form .u--justify-space-between .form-group fieldset {
  margin-bottom: 0 !important;
}
.taxi-form legend.label:empty {
  display: none;
}
/* THIS IS A TEMP FIX TO MAKE THE CHECKBOXES ALIGN */
.taxi-form .u--aic,
.taxi-form .u--align-items-center {
  align-items: center !important;
}
.taxi-form .u--sf,
.taxi-form .u--show-flex {
  display: flex !important;
}
.taxi-form .button,
.taxi-form .button:active,
.taxi-form .button:focus,
.taxi-form .button:hover {
  text-decoration: none;
}
.taxi-form .button--danger {
  background: #d12c1a;
  background: linear-gradient(180deg, #d12c1a, #ba2717);
  border: 0;
  color: #fff;
  padding: calc(0.40625rem + 1px) calc(0.9375rem + 1px);
}
.taxi-form .tooltip {
  display: inline-block;
  position: relative;
}
.taxi-form .tooltip:after {
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #333;
  border-radius: 4px;
  bottom: 100%;
  color: #fff;
  content: attr(aria-label);
  letter-spacing: normal;
  margin-bottom: 5px;
  padding: 0.25rem 0.75rem;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  transform: translate3d(50%, 0, 0) scale(1);
  white-space: pre;
  z-index: 1000000;
}
.taxi-form .tooltip:after,
.taxi-form .tooltip:before {
  display: none;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 50%;
}
.taxi-form .tooltip:before {
  border: 5px solid #0000;
  border-top-color: #333;
  bottom: auto;
  color: #333;
  content: "";
  height: 0;
  margin-right: -5px;
  top: -5px;
  width: 0;
  z-index: 1000001;
}
@keyframes tooltip-appear {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.taxi-form .tooltip:active:after,
.taxi-form .tooltip:active:before,
.taxi-form .tooltip:focus:after,
.taxi-form .tooltip:focus:before,
.taxi-form .tooltip:hover:after,
.taxi-form .tooltip:hover:before {
  animation-delay: 0.25s;
  animation-duration: 0.125s;
  animation-fill-mode: forwards;
  animation-name: tooltip-appear;
  animation-timing-function: ease-in;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  text-decoration: none;
}
.taxi-form .tooltip--top-left:after,
.taxi-form .tooltip--top-right:after {
  transform: none;
}
.taxi-form .tooltip--top-right:after {
  left: 50%;
  margin-left: -15px;
  right: auto;
}
.taxi-form .tooltip--top-left:after {
  margin-right: -15px;
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.taxi-form .spinner {
  display: inline-block;
  height: 3rem;
  line-height: 1;
  position: relative;
  width: 3rem;
}
.taxi-form .spinner:after,
.taxi-form .spinner:before {
  animation: spin 0.7s linear infinite;
  border-color: #fff #fff #0000 #0000;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  box-sizing: initial;
  content: "";
  display: block;
  height: 100%;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 100%;
}
.taxi-form .spinner:after {
  animation-duration: 1.4s;
  border-width: 1px;
  margin: 3px;
}
.taxi-form .spinner--small {
  height: 1rem;
  width: 1rem;
}
.taxi-form .spinner--small:after,
.taxi-form .spinner--small:before {
  border-color: #fff #fff #fff #0000;
  border-width: 2px;
  left: -2px;
  top: -2px;
}
.taxi-form .spinner--small:after {
  display: none;
}
