.exec-ed-taxi-form .radio input {
  border-radius: 50% !important;
}

.exec-ed-taxi-form .form-group {
  margin-bottom: 1rem !important; /* Default margin for all form-group divs */
}

.exec-ed-taxi-form .form-group:nth-child(n + 4) {
  margin-bottom: 8px !important; /* Change margin for divs starting from the 4th div */
}

.exec-ed-taxi-form
  #more_info_desktop-lead-form-field-mount-point
  > div.form-group
  > fieldset
  > legend {
  margin-bottom: 0 !important;
}

.exec-ed-taxi-form
  #more_info_desktop-lead-form-field-mount-point
  > div.form-group.u--mb0
  > fieldset
  > div
  > div {
  margin-bottom: 0 !important;
}

.exec-ed-taxi-form
  #more_info_desktop-lead-form-field-mount-point
  > div.form-group
  > fieldset {
  margin-bottom: 0 !important;
}

.exec-ed-taxi-form .form-group > .label {
  display: none;
}

.exec-ed-taxi-form div[aria-label="Email"] > .form-group:first-of-type {
  display: none;
}

.exec-ed-taxi-form .radio {
  background-color: #f3f1ed !important;
}

.exec-ed-taxi-form .radio > label {
  font-size: 12px;
}

.exec-ed-taxi-form fieldset.form-group > legend.label {
  font-size: 12px;
  font-weight: normal;
  display: block;
}

.exec-ed-taxi-form
  #more_info_desktop-lead-form-field-mount-point
  > div.form-group.u--mb0
  > fieldset
  > div {
  margin-bottom: 0 !important;
}

.exec-ed-taxi-form
  #more_info_desktop-lead-form-field-mount-point
  > div.form-group.u--mb0
  > fieldset
  > div {
  height: 31px !important;
}

#more_info_desktop-lead-form-next-action-button {
  margin-top: 16px !important;
}
